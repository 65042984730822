import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  img {
    margin-top: 50px;
    min-width: 50%;
  }
`;

const getNumberOfFile = (filePath) => {
  return filePath.split(".jpeg")[0].split("/").pop();
};

export default ({ data }) => {
  const images = data.allFile.edges.map((image) => image.node.publicURL);
  images.sort((a, b) => getNumberOfFile(a) - getNumberOfFile(b));

  return (
    <Wrapper>
      {images.map((image) => {
        return <img key={image} src={image} />;
      })}
    </Wrapper>
  );
};

export const indexQuery = graphql`
  query MyQuery {
    allFile(
      filter: { relativeDirectory: { eq: "images/house" } }
      sort: { fields: [base] }
    ) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`;
